<script lang="ts" setup>
const isShowBanner = useCookie('isShowBanner', {
  default: () => true,
})
const { t } = useI18n()
const toggleShowBanner = useToggle(isShowBanner)
</script>

<template>
  <Transition>
    <div
      class="bg-[#7540EE] w-full h-21.25 flex flex-row items-center overflow-hidden z-3 relative"
      v-show="isShowBanner"
    >
      <div class="flex flex-row justify-center w-full pl-2.5">
        <div class="flex justify-between max-w-274 w-full">
          <div class="flex flex-row items-center gap-5">
            <div
              class="text-white text-xs min-[414px]:text-sm sm:text-base tracking-wide"
            >
              Try our Gasless NFT checkout (powered by Belong SDK)
            </div>
            <NuxtLink
              to="https://app.belong.net/payments?target=event-ticket&eventId=6706cb091a118ca18749b42d"
              target="_blank"
              class="flex flex-row items-center text-center justify-center bg-black text-sm sm:text-base rounded-full pl-3.5 pr-4.5 py-1.5 whitespace-pre"
            >
              <p class="text-white">Mint free pass</p>
            </NuxtLink>
          </div>
        </div>
        <button class="cursor-pointer ml-3" @click="toggleShowBanner(false)">
          <img
            class="w-9.75 h-9.75 object-cover"
            :alt="t('banner-close')"
            src="~/assets/images/home/banner-close.svg"
          />
        </button>
      </div>
    </div>
  </Transition>
</template>
